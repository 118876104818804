<template>
  <div v-if="categoriesData" class="table-container">
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th colspan="3">{{ $t("Categories.data") }}</th>
          <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.name") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(category, index) in categoriesData" :key="index">
          <td>{{ ++index + filterData.currentIndex }}</td>
          <td>
            <img
              class="item-img-table"
              :src="
                fullPathFileFromServer(category.categoryImagePath, defaultImg)
              "
              :onerror="`this.src='${defaultImg}'`"
            />
          </td>
          <td>{{ isDataExist(category.fullCode) }}</td>
          <td>
            {{ isDataExist(category.categoryNameCurrent) }}
          </td>
          <td class="fmenu-item-container">
            <FloatingMenu>
              <li>
                <button
                  @click="
                    setCategoryData(category);
                    openBottomSheet('CategoryInfo');
                  "
                  :title="$t('info')"
                >
                  <img src="@/assets/images/info.svg" />
                </button>
              </li>
              <li>
                <button
                  @click="
                    setCategoryData(category);
                    openBottomSheet('CategoryQRCode');
                  "
                  :title="$t('general.qrCode')"
                >
                  <img src="@/assets/images/qr-code.svg" />
                </button>
              </li>
              <li v-if="checkPrivilege(hasCategoryEdit())">
                <button
                  @click="
                    setCategoryData(category);
                    openBottomSheet('CategoryUpdate');
                  "
                  :title="$t('edit')"
                >
                  <img src="@/assets/images/pencil.svg" />
                </button>
              </li>
              <li v-if="checkPrivilege(hasCategoryFinaleDelete())">
                <button
                  v-b-modal.CategoryDelete
                  :title="$t('delete')"
                  @click="setCategoryData(category)"
                >
                  <img src="@/assets/images/trash.svg" />
                </button>
              </li>
              <li v-if="checkPrivilege(hasCategoryChangeActivationType())">
                <button
                  v-b-modal.CategoryChangeActivationType
                  :title="$t('changeActivationType')"
                  @click="setCategoryData(category)"
                >
                  <img src="@/assets/images/changeActivationType.svg" />
                </button>
              </li>
              <li>
                <button
                  @click="
                    setCategoryData(category);
                    openBottomSheet('ActionsData');
                  "
                  :title="$t('actionsData.modelName')"
                >
                  <img src="@/assets/images/actions-data.svg" />
                </button>
              </li>
            </FloatingMenu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../../../utils/functions";
import {
  hasCategoryEdit,
  hasCategoryFinaleDelete,
  hasCategoryChangeActivationType,
} from "./../../../../../utils/privilegeHelper";
import FloatingMenu from "./../../../../../components/general/FloatingMenu.vue";
import generalMixin from "./../../../../../utils/generalMixin";

export default {
  name: "CategoriesTable",
  mixins: [generalMixin],
  components: {
    FloatingMenu,
  },
  props: ["categoriesData", "filterData", "defaultImg"],
  methods: {
    setCategoryData(category) {
      this.$emit("setCategoryData", category);
    },
    checkPrivilege,
    isDataExist,
    fullPathFileFromServer,
    hasCategoryEdit,
    hasCategoryFinaleDelete,
    hasCategoryChangeActivationType,
  },
};
</script>
