<template>
  <CustomBottomSheet
    :refName="'CategoryInfo'"
    size="xl"
    :headerText="$t('Categories.data')"
    :headerIcon="category.icon"
  >
    <div class="row">
      <DataLabelGroup
        :className="'col-md-6'"
        :value="category.fullCode"
        :title="$t('Categories.code')"
        :imgName="'code.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="category.categoryNameAr"
        :title="$t('Categories.nameAr')"
        :imgName="'categories.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="category.categoryNameEn"
        :title="$t('Categories.nameEn')"
        :imgName="'categories.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="category.categoryNameUnd"
        :title="$t('Categories.nameUnd')"
        :imgName="'categories.svg'"
      />

      <DataLabelGroup
        :className="'col-md-6'"
        :value="category.categoryDescriptionAr"
        :title="$t('Categories.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="category.categoryDescriptionEn"
        :title="$t('Categories.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="category.categoryDescriptionUnd"
        :title="$t('Categories.descriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="category.categoryNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "./../../../../../components/general/CustomBottomSheet.vue";
import DataLabelGroup from "./../../../../../components/general/DataLabelGroup.vue";

export default {
  components: {
    CustomBottomSheet,
    DataLabelGroup,
  },
  props: ["category"],
};
</script>
