<template>
  <div class="">
    <DashboardNavbar
      v-on:updateViewType="updateViewType('categories', $event)"
      :viewType="generalSetting.categories.viewType"
      :viewTypeStatus="true"
      v-on:updateActivationTypeTokens="changeActivationType($event)"
      :activationTypeTokens="generalSetting.categories.activationTypeTokens"
      :activationTypesTokensOptions="activationTypesTokensOptions"
      :activationTypeStatus="true"
      :btnAddTitle="$t('Categories.add')"
      :btnAddName="'CategoryAdd'"
      :btnAddStatus="checkPrivilege(hasCategoryAdd())"
      :filterSheetName="'filterSheet'"
      :filterSheetStatus="true"
    />
    <PreLoader v-if="isLoading" />

    <div class="app-content pagination-with-content">
      <template v-if="hasData">
        <CategoryTable
          class="animate__animated animate__bounceIn"
          v-if="generalSetting.categories.viewType == VIEW_TYPES.table"
          v-on:setCategoryData="categories.category.fillData($event)"
          :categoriesData="categories.categoriesData"
          :defaultImg="categories.category.defaultImg"
          :filterData="categories.filterData"
        />

        <CategoryCards
          class="animate__animated animate__bounceIn"
          v-else-if="generalSetting.categories.viewType == VIEW_TYPES.cards"
          v-on:setCategoryData="categories.category.fillData($event)"
          :categoriesData="categories.categoriesData"
          :defaultImg="categories.category.defaultImg"
          :filterData="categories.filterData"
        />

        <ActionsData :actionsData="categories.category" />
        <CategoryInfo :category="categories.category" />
        <CategoryDelete
          :category="categories.category"
          v-on:refresh="getAllCategories()"
        />
        <CategoryChangeActivationType
          :category="categories.category"
          v-on:refresh="getAllCategories()"
        />
        <CustomBottomSheetQRCode
          :refName="'CategoryQRCode'"
          :code="categories.category.fullCode"
          :codeTitle="$t('Categories.code')"
          :name="categories.category.categoryNameCurrent"
          :nameTitle="$t('Categories.name')"
          :nameIcon="'categories.svg'"
        />
      </template>
      <ExceptionWithImg
        v-if="exceptionMsg"
        :msg="exceptionMsg"
        :image="exceptionImg"
      />
      <CustomPagination
        v-if="hasData"
        :paginationData="categories.filterData"
        v-on:changePagination="changePagination($event)"
      />
      <CategoryFilter
        :theFilterData="categories.filterData"
        v-on:search="search($event)"
      />

      <CategoryAdd
        :category="categories.category"
        v-on:refresh="getAllCategories()"
      />
      <CategoryUpdate
        :category="categories.category"
        v-on:refresh="getAllCategories()"
      />
    </div>
  </div>
</template>

<script>
import { STATUS, VIEW_TYPES } from "./../../../../utils/constants";
import { ACTIVATION_TYPE } from "./../../../../utils/constantLists";
import DashboardNavbar from "./../../../../layouts/components/DashboardNavbar.vue";
import PreLoader from "./../../../../components/general/PreLoader.vue";
import ExceptionWithImg from "./../../../../components/general/ExceptionWithImg.vue";
import ActionsData from "./../../../../components/general/ActionsData.vue";
import CustomBottomSheetQRCode from "./../../../../components/general/CustomBottomSheetQRCode.vue";
import CustomPagination from "./../../../../components/general/CustomPagination.vue";
import CategoryAdd from "./components/CategoryAdd.vue";
import CategoryUpdate from "./components/CategoryUpdate.vue";
import CategoryCards from "./components/CategoryCards.vue";
import CategoryTable from "./components/CategoryTable.vue";
import CategoryInfo from "./components/CategoryInfo.vue";
import CategoryDelete from "./components/CategoryDelete.vue";
import CategoryChangeActivationType from "./components/CategoryChangeActivationType.vue";
import CategoryFilter from "./components/CategoryFilter.vue";
import Categories from "./../../../../models/settings/settingsOthers/categories/Categories";
import apiCategory from "./../../../../api/settings/settingsOthers/categories";
import { getDialogOfActivationTypes } from "./../../../../utils/dialogsOfConstantsLists";
import generalMixin from "./../../../../utils/generalMixin";
import generalSettingsMixin from "./../../../../utils/generalSettingsMixin";
import { checkPrivilege } from "./../../../../utils/functions";
import {
  hasCategoryAdd,
  hasCategoryViewActive,
  hasCategoryViewBlocked,
} from "./../../../../utils/privilegeHelper";

export default {
  mixins: [generalMixin, generalSettingsMixin],
  components: {
    DashboardNavbar,
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    CustomBottomSheetQRCode,
    CustomPagination,
    CategoryAdd,
    CategoryUpdate,
    CategoryCards,
    CategoryTable,
    CategoryInfo,
    CategoryDelete,
    CategoryChangeActivationType,
    CategoryFilter,
  },
  computed: {
    hasData() {
      return this.categories.categoriesData.length != 0;
    },
    activationTypesTokensOptions: function () {
      let arr = [];
      if (checkPrivilege(hasCategoryViewActive())) {
        let obj = this.activationTypesTokenOptions.find(
          (e) => e.value === ACTIVATION_TYPE.Active
        );
        obj.totalNumber =
          this.categories.activationStatistics.totalActiveCount || 0;
        if (obj) arr.push(obj);
      }

      if (checkPrivilege(hasCategoryViewBlocked())) {
        let obj = this.activationTypesTokenOptions.find(
          (e) => e.value === ACTIVATION_TYPE.Blocked
        );
        obj.totalNumber =
          this.categories.activationStatistics.totalBlockedCount || 0;
        if (obj) arr.push(obj);
      }
      return arr;
    },
  },
  data() {
    return {
      categories: new Categories(),
      VIEW_TYPES,
      activationTypesTokenOptions: getDialogOfActivationTypes(),
    };
  },
  methods: {
    checkPrivilege,
    hasCategoryAdd,
    changePagination(pagination) {
      this.categories.filterData.fillData(pagination);
      this.getAllCategories();
    },
    changeActivationType(data) {
      this.updateActivationTypeTokens("categories", data);
      this.categories.filterData.activationTypeTokens = data;
      this.getAllCategories();
    },
    search(data) {
      this.categories.filterData.fillData(data);
      this.getAllCategories();
    },
    async getAllCategories() {
      this.isLoading = true;
      try {
        this.categories.categoriesData = [];
        const response = await apiCategory.getAll(this.categories.filterData);
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noInternet.svg";
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.categories.fillData(response.data);
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllCategories();
  },
};
</script>
